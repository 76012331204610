<template>
  <div class="p-3 pt-0">
    <div class="limit p-3">
      <h1 class="title-header">Configurações</h1>
      <div class="content">
        <div class="content-left">
          <h1>Preferências</h1>
          <!-- <ShareInformation /> -->
          <Notifications />
          <PersonalAddress />
        </div>
        <div class="content-right">
          <h1>Segurança</h1>
          <ManagePassword :view="securityView" @toggle="changeView" />
          <ManageEmail :view="securityView" @toggle="changeView" />
          <ManagePhone :view="securityView" @toggle="changeView" />
          
          <ConnectedDevices :view="securityView" @toggle="changeView" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import ShareInformation from "./ShareInformation.vue";
import Notifications from "./Notifications.vue";
import ManagePassword from "./ManagePassword.vue";
import ManageEmail from "./ManageEmail.vue";
import ManagePhone from "./ManagePhone.vue";
import PersonalAddress from "./PersonalAddress.vue";
import ConnectedDevices from "./ConnectedDevices.vue";

export default {
  components: {
    ShareInformation,
    Notifications,
    ManagePassword,
    ManageEmail,
    ManagePhone,
    PersonalAddress,
    ConnectedDevices,
  },
  setup() {
    const securityView = ref({
      password: false,
      email: false,
      phone: false,
      address: false,
      devices: false,
    });

    const changeView = (strObj) => {
      if (securityView.value[strObj]) {
        securityView.value = {
          password: false,
          email: false,
          phone: false,
          address: false,
          devices: false,
        };
      } else {
        securityView.value = {
          password: false,
          email: false,
          phone: false,
          address: false,
          devices: false,
        };
        securityView.value[strObj] = true;
      }
    };

    return { securityView, changeView };
  },
};
</script>

<style lang="scss" scoped>
.limit {
  max-width: 100%;
}
.btn {
  width: max-content;
  height: 40px;
}
.content {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 36px;
  .content-left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #bdbdbd;
    }
  }
  .content-right {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #bdbdbd;
    }
  }
  .details-content {
    background: white;
    width: 100%;
    margin-top: -5px;
    padding: 8px 18px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
      0px 3px 6px rgba(61, 71, 102, 0.06);
  }
  h1 {
    color: #4f4f4f;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
  .p-14 {
    padding: 14px;
  }
  @media (max-width: 1211px) {
    flex-direction: column;
    .content-left {
      width: 100%;
    }
    .content-right {
      width: 100%;
    }
  }
}
details > summary {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 18px;
  width: 100%;
  height: 56px;
  background: white;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
    0px 3px 6px rgba(61, 71, 102, 0.06);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  list-style: none;
  font-weight: bold;
}
</style>