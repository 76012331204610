<template>
  <div class="details">
    <div class="pointer header" @click="view = !view">
      <h1>
        <div class="box-icon">
          <img src="../../assets/icons/settings/share_informations.png" alt="" />
        </div>
        Compartilhar Informações
      </h1>
      <img
        class="pointer"
        src="../../assets/icons/settings/close.png"
        alt=""
        v-if="view"
      />
      <img
        class="pointer"
        src="../../assets/icons/settings/open.png"
        alt=""
        v-else
      />
    </div>
    <div class="content" v-if="view">
      <!-- TODO: Novos Agendamentos -->
      <div class="item" v-for:="item in items">
        <div
          class="flex justify-content-between align-items-center"
          :style="
            viewSubItems === item.obj
              ? 'opacity: 1;'
              : viewSubItems === null
              ? 'opacity: 1;'
              : 'opacity: 0.5;'
          "
        >
          <p>{{ item.label }}</p>
          <div class="right" style="">
            <InputSwitch
              v-model="options[item.obj]"
              @change="changeAll(item.obj)"
            />
            <img
              class="pointer p-1"
              src="../../assets/icons/settings/down.png"
              alt=""
              @click="changeViewSubItems(item.obj)"
            />
          </div>
        </div>

        <div class="flex flex-column mt-3" v-if="viewSubItems === item.obj">
          <div class="flex flex-column" v-if="item.obj === 'shared_content'">
            <div class="sub-items">
              <div class="icons-sub">
                <div class="icon">
                  <img src="../../assets/icons/settings/schedule.png" />
                </div>  
                <p style="margin-left: 4px;">Agenda</p>
              </div>
              <InputSwitch
                v-model="item.sub_items.schedule"
                @change="leastOneSubActive(item.obj)"
                style="margin-right: 52px;"
              />
            </div>

            <div class="sub-items">
              <div class="icons-sub">
                <div class="icon">
                  <img src="../../assets/icons/settings/exams.png" />
                </div>                
                <p style="margin-left: 4px;">Exames</p>
              </div>
              
              <InputSwitch
                v-model="item.sub_items.exams"
                @change="leastOneSubActive(item.obj)"
                style="margin-right: 52px;"
              />
            </div>

            <div class="sub-items">
              <div class="icons-sub">
                <div class="icon">
                  <img src="../../assets/icons/settings/patients.png" />
                </div>  
                <p style="margin-left: 4px;">Pacientes</p>
              </div>
              <InputSwitch
                v-model="item.sub_items.patients"
                @change="leastOneSubActive(item.obj)"
                style="margin-right: 52px;"
              />
            </div>

            <div class="sub-items no-border-bottom pb-0">
              <div class="icons-sub">
                <div class="icon">
                  <img src="../../assets/icons/settings/services.png" />
                </div>  
                <p style="margin-left: 4px;">Serviços</p>
              </div>
              <InputSwitch
                v-model="item.sub_items.services"
                @change="leastOneSubActive(item.obj)"
                style="margin-right: 52px;"
              />
            </div>
          </div>
          <div class="flex flex-column" v-else>
            <div class="sub-items" v-for:="peaple in item.sub_items">
              <div class="flex align-items-center">
                <img :src="peaple.img" class="profile" alt="" />
                <p class="name">{{ peaple.name }}</p>
              </div>
              <InputSwitch
                v-model="peaple.active"
                @change="leastOneSubActive(item.obj)"              
                style="margin-right: 52px;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  // onMounted
} from "vue";
import avatarProf1 from "../../assets/avatares/professionals/4.png";
import avatarProf2 from "../../assets/avatares/professionals/6.png";
import avatarProf3 from "../../assets/avatares/professionals/10.png";
import avatarProf4 from "../../assets/avatares/professionals/5.png";
export default {
  emits: {},
  setup() {
    const view = ref(false);
    const viewSubItems = ref(null);
    const options = ref({
      shared_content: false,
      partners_access: false,
    });
    const items = ref([
      {
        label: "Conteúdo Compartilhado",
        obj: "shared_content",
        sub_items: {
          schedule: false,
          exams: false,
          patients: false,
          services: false,
        },
      },
      {
        label: "Parceiros com acesso",
        obj: "partners_access",
        sub_items: [
          { name: "Dr. Aurélio Brandão", img: avatarProf1, active: false },
          { name: "Dra. Amanda Cordeiro", img: avatarProf2, active: false },
          { name: "Dra. Noneci Cordeiro De Jesus", img: avatarProf3, active: false },
          { name: "Dr. Victor Pantoja", img: avatarProf4, active: false },
        ],
      },
    ]);

    // onMounted(() => {
    //   console.log("montou");
    //   console.log("options: ", options.value);
    //   console.log("items:", items.value);
    // });

    const changeAll = (strObj) => {
      const bool = options.value[strObj];

      if (bool) {
        if (strObj === "shared_content") {
          items.value[0].sub_items = {
            schedule: true,
            exams: true,
            patients: true,
            services: true,
          };
        } else {
          items.value[1].sub_items.map((item) => {
            item.active = true;
          });
        }
      } else {
        if (strObj === "shared_content") {
          items.value[0].sub_items = {
            schedule: false,
            exams: false,
            patients: false,
            services: false,
          };
        } else {
          items.value[1].sub_items.map((item) => {
            item.active = false;
          });
        }
      }
    };

    const changeViewSubItems = (strObj) => {
      if (viewSubItems.value === strObj) {
        viewSubItems.value = null;
      } else {
        viewSubItems.value = strObj;
      }
    };

    const leastOneSubActive = (strObj) => {
      if (strObj === "partners_access") {
        const oneActive = items.value[1].sub_items.find(
          (profile) => profile.active
        );
        if (oneActive) {
          options.value.partners_access = true;
        } else {
          options.value.partners_access = false;
        }
      } else {
        items.value.map((item) => {
          if (item.obj === strObj) {
            if (
              item.sub_items.schedule ||
              item.sub_items.exams ||
              item.sub_items.patients ||
              item.sub_items.services
            ) {
              console.log(strObj + " : pelo menos um ativo");
              options.value[strObj] = true;
            } else {
              console.log(strObj + " : nenhum item ativo");
              options.value[strObj] = false;
            }
          }
        });
      }
    };

    return {
      view,
      options,
      items,
      viewSubItems,
      changeAll,
      changeViewSubItems,
      leastOneSubActive,
    };
  },
};
</script>

<style lang="scss" scoped>
.details {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
    0px 3px 6px rgba(61, 71, 102, 0.06);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0px 18px;
    h1 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
  .content {
    padding: 0 18px;
    .item {
      display: flex;
      flex-direction: column;
      padding: 18px 0;
      border-bottom: 1px solid #f2f2f2;
      p {
        color: #4f4f4f;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }
      .right {
        display: flex;
        align-items: center;
        gap: 29px;
        margin-right: 6px;
      }
      .sub-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 1px solid #f2f2f2;
        margin-left: 0 !important;
        p {
          color: #4f4f4f;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
        }
        .right {
          display: flex;
          align-items: center;
          gap: 29px;
          margin-right: 6px;
        }
        .icons-sub{
          display: flex;
          gap: 4px;
          align-items: center;
          .icon{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .container-radios {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #fafafa;
      height: 110px;
    }
    .profile {
      height: 40px;
      width: 40px;
      border-radius: 8px;
    }
    .name {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #4f4f4f;
      margin-left: 8px;
    }
  }
}
.no-border-bottom {
  border-bottom: none !important;
}
.btn {
  width: max-content;
  height: 40px;
}
.pointer {
  cursor: pointer;
}
.box-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
::v-deep(.p-radiobutton-box) {
  border: 1px #bdbdbd solid;
}
::v-deep(.field-radiobutton) {
  margin-bottom: 0px;
}
</style>